import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout';
import FlexibleContent from '~/components/FlexibleContent';
import HeroPricing from '~/components/organisms/HeroPricing';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import CustomSEO from '~/components/seo/CustomSEO';

export default function Pricing({ data: { wpPage } }) {
  // console.log('~~~Pricing.js');

  const page = wpPage.pricing.pricing

  const content = [
    ...wpPage.page.page.content,
    ...wpPage.pricing.pricing.content,
  ]

  return (
    <Layout headerWhite={wpPage.page.page.whiteHeader} backgroundColour={wpPage.page.page.backgroundColour} showLogin={wpPage.page.page.showLogin} footerCtaType={wpPage.page.page.footerCtaType} footerResourcesType={wpPage.page.page.footerResourcesType} pageLanguage={wpPage.page.page.pageLanguage}>
      <Seo post={wpPage} />
      <CustomSEO seo={wpPage?.seo} />
      <HeroPricing {...page} className="!bg-transparent !mb-0 md:!mb-0" />
      <FlexibleContent content={content} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query pricingPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      page {
        page {
          backgroundColour
          whiteHeader
          showLogin
          footerCtaType
          footerResourcesType
          pageLanguage
          content {
            ... on WpPage_Page_Page_Content_PricingWidget {
              fieldGroupName
              pricingWidget {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText
                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                simpletext
                simpletextSecond
                simpletextThird
              }
            }
           ... on WpPage_Page_Page_Content_Reviews {
              fieldGroupName
              reviews {
                fieldGroupName
                heading
                reviewsData{
                review
                stars
                }
                # type
                # rating
                # count
              }
            }
          }
        }
      }
      pricing {
        pricing {
          heroPricing {
            fieldGroupName
            textCard {
              illustration {
                ... Image
              }
              subheading
              subheadingColor
              alignText
              heading {
                text
                type
              }
              content
              buttons {
                button {
                  button {
                    button {
                      ... Button
                    }
                  }
                  type
                }
              }
            }
            language
          }
          content {
            ... on WpPage_Pricing_Pricing_Content_Faqs {
              fieldGroupName
              faqs {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                faqs {
                  question
                  fieldGroupName
                  answer
                }
                link {
                  ... Button
                }
              }
            }
          }
        }
      }
    }
  }
`
